<template>
  <div class="home">
    <header>
      <m-page-header title="数据统计" />
    </header>
    <main>
      <div class="select_time">
        <div>
          <m-date-picker
            v-model="defaultValue"
            timeType="daterange"
            size="medium"
            @timeChange="timeChange"
          />
        </div>
        <div><el-button type="primary" size="medium" @click="getstatis">查询</el-button></div>
      </div>
      <div class="state_list">
        <el-row :gutter="10">
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <m-form-skeleton type="mini-text" :loading="loading">
                <h2>{{ statisData.goods_count }}</h2>
                <p class="goods_number">登记商品数量</p>
                <p class="user_total">用户登记的商品总数</p>
              </m-form-skeleton>
            </div>
          </el-col>
          <el-col :span="6"
            ><div class="grid-content bg-purple">
              <m-form-skeleton type="mini-text" :loading="loading">
                <h2>{{ statisData.user_count }}</h2>
                <p class="goods_number">登记用户数量</p>
                <p class="user_total">登记降价提醒的总用户数</p>
              </m-form-skeleton>
            </div></el-col
          >
          <el-col :span="6"
            ><div class="grid-content bg-purple">
              <m-form-skeleton type="mini-text" :loading="loading">
                <h2>{{ statisData.subscribe_count }}</h2>
                <p class="goods_number">设置登记次数</p>
                <p class="user_total">登记降价提醒的总次数</p>
              </m-form-skeleton>
            </div></el-col
          >
          <el-col :span="6"
            ><div class="grid-content bg-purple">
              <m-form-skeleton type="mini-text" :loading="loading">
                <h2>{{ statisData.send_count }}</h2>
                <p class="goods_number">已发送邮件总数</p>
                <p class="user_total">已发送给用户的邮件总数</p>
              </m-form-skeleton>
            </div></el-col
          >
        </el-row>
        <el-row :gutter="10">
          <el-col :span="6"
            ><div class="grid-content bg-purple">
              <m-form-skeleton type="mini-text" :loading="loading">
                <h2>{{ statisData.cart_send_count }}</h2>
                <p class="goods_number">购物车降价邮件数</p>
                <p class="user_total">购物车商品降价发送的邮件总数</p>
              </m-form-skeleton>
            </div></el-col
          >
          <el-col :span="6"
            ><div class="grid-content bg-purple">
              <m-form-skeleton type="mini-text" :loading="loading">
                <h2>{{ statisData.open_count }}</h2>
                <p class="goods_number">邮件查看次数</p>
                <p class="user_total">打开邮件查看的数量</p>
              </m-form-skeleton>
            </div></el-col
          >
          <el-col :span="6"
            ><div class="grid-content bg-purple">
              <m-form-skeleton type="mini-text" :loading="loading">
                <h2>{{ statisData.click_count }}</h2>
                <p class="goods_number">邮件点击次数</p>
                <p class="user_total">邮件内容的点击数量</p>
              </m-form-skeleton>
            </div></el-col
          >
          <el-col :span="6"
            ><div class="grid-content bg-purple">
              <m-form-skeleton type="mini-text" :loading="loading">
                <h2>{{ statisData.conversion_rate }}</h2>
                <p class="goods_number">邮件-支付转化率</p>
                <p class="user_total">登记及购物车商品降价且被购买的邮件总数/发送邮件总数</p>
              </m-form-skeleton>
            </div></el-col
          >
        </el-row>
        <el-row :gutter="10">
          <el-col :span="6"
            ><div class="grid-content bg-purple">
              <m-form-skeleton type="mini-text" :loading="loading">
                <h2>
                  <span style="font-size: 28px">{{ currency.symbol_left + ' ' }}</span>
                  <span>{{ statisData.order_amount_sum }}</span>
                  <span style="font-size: 28px">{{ ' ' + currency.symbol_right }}</span>
                </h2>
                <p class="goods_number">邮件-支付转化金额</p>
                <p class="user_total">登记及购物车商品降价发送邮件且被购买商品的总金额</p>
              </m-form-skeleton>
            </div></el-col
          >
        </el-row>
      </div>
    </main>
  </div>
</template>

<script>
import { statis } from '../api/DataStat';
import { getdetail } from '../api/PricecutsremindSetting';
import { parseTime } from '@/tools/index';
import { ref } from 'vue';

export default {
  name: 'dataStat',
  setup() {
    function timeChange(time) {
      serach.value.begin = time.startime;
      serach.value.end = time.endtime;
    }
    const defaultValue = ref([]); //默认时间
    const loading = ref(true);
    const currency = ref('');
    const statisData = ref('');
    const serach = ref({
      begin: '',
      end: '',
    });
    async function getstatis() {
      loading.value = true;
      const { data } = await statis(serach.value);
      statisData.value = data.data;
      currency.value = data.currency;
      loading.value = false;
    }
    const getConfing = async () => {
      const { data } = await getdetail();
      const attribution_days = Number(data.attribution_days);
      defaultValue.value = [
        addDate(parseTime(new Date(), '{y}-{m}-{d}'), -+attribution_days),
        new Date(),
      ];
      serach.value.begin = addDate(parseTime(new Date(), '{y}-{m}-{d}'), -+attribution_days);
      serach.value.end = parseTime(new Date(), '{y}-{m}-{d}');
      getstatis();
    };
    //根据天数算出时间
    function addDate(dateTime, days) {
      if (days == undefined || days == '') {
        days = 1;
      }
      var date = new Date(dateTime);
      date.setDate(date.getDate() + days);
      var month = date.getMonth() + 1;
      var day = date.getDate();
      return date.getFullYear() + '-' + getFormatDate(month) + '-' + getFormatDate(day);
    }
    function getFormatDate(time) {
      return ('0' + time).slice(-2);
    }
    return {
      timeChange,
      getstatis,
      getConfing,
      addDate,
      defaultValue,
      currency,
      loading,
      serach,
      statisData,
    };
  },
  mounted() {
    this.getConfing();
  },
};
</script>

<style scoped lang="scss">
.select_time {
  padding: 15px;
  display: flex;
  align-items: center;
  > div {
    margin-right: 20px;
    button {
      min-width: 100px;
    }
  }
}
.state_list {
  background-color: #f3f3f3;
  padding-bottom: 10px;
  .grid-content {
    background-color: #fff;
    margin-top: 10px;
    text-align: center;
    padding: 50px 30px;
    box-sizing: border-box;
    min-height: 220px;
    h2 {
      font-size: 36px;
      color: #333;
      font-weight: bold;
    }
    .goods_number {
      font-size: 16px;
      padding: 20px 0;
      font-weight: bold;
    }
    .user_total {
      font-size: 14px;
      color: #999999;
    }
  }
}
</style>
